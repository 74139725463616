export interface RawPreviewClient {
    0: string;
    1: string;
    2: string;
    3: string;
    4: string;
    5: number;
    6: string;
    7: string;
    8: number;
}

export interface PreviewClient {
    name: RawPreviewClient[0];
    email: RawPreviewClient[1];
    phone: RawPreviewClient[2];
    addresse: RawPreviewClient[3];
    addresseSecondLine: RawPreviewClient[4];
    postcode: RawPreviewClient[5];
    city: RawPreviewClient[6];
    country: RawPreviewClient[7];
    vat: RawPreviewClient[8];
}

export default class ClientPreview {
    private _name: string;
    private _email: string;
    private _phone: string;
    private _adresse: string;
    private _adresseSecondLine: string;
    private _postcode: number;
    private _city: string;
    private _country: string;
    private _vat: number;

    constructor(client: PreviewClient) {
        this._name = client.name;
        this._email = client.email;
        this._phone = client.phone;
        this._adresse = client.addresse;
        this._adresseSecondLine = client.addresseSecondLine;
        this._postcode = client.postcode;
        this._city = client.city;
        this._country = client.country;
        this._vat = client.vat;
    }

    get name() {
        return this._name;
    }

    set name(value: string) {
        this.name = value;
    }

    get email() {
        return this._email;
    }

    set email(value: string) {
        this.email = value;
    }

    get phone() {
        return this._phone;
    }

    set phone(value: string) {
        this.phone = value;
    }

    get addresse() {
        return this._adresse;
    }

    set addresse(value: string) {
        this.addresse = value;
    }

    get addresseSecondLine() {
        return this._adresseSecondLine;
    }

    set addresseSecondLine(value: string) {
        this.addresseSecondLine = value;
    }

    get postcode() {
        return this._postcode;
    }

    set postcode(value: number) {
        this.postcode = value;
    }

    get city() {
        return this._city;
    }

    set city(value: string) {
        this.city = value;
    }

    get country() {
        return this._country;
    }

    set country(value: string) {
        this.country = value;
    }

    get vat() {
        return this._vat;
    }

    set vat(value: number) {
        this.vat = value;
    }
}
