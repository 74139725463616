<script setup lang="ts">
    const props = defineProps({
        collapsed: {
            type: Boolean,
            required: true,
        },
        to: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        extraActivePath: {
            type: String,
            required: false,
        },
        exactlyActive: {
            type: Boolean,
            default: false,
        },
    });

    const isActive = computed(() => {
        if (props.extraActivePath && useRoute().path.includes(props.extraActivePath)) {
            return true;
        }

        return props.exactlyActive ? useRoute().path === props.to : useRoute().path.includes(props.to);
    });
</script>

<template>
    <div :class="$theme('layout.sidebar_link_container')">
        <NuxtLink
            :to="to"
            :active-class="$theme('layout.sidebar_link_active')"
            :class="[collapsed ? $theme('layout.sidebar_link_collapsed') : $theme('layout.sidebar_link'), isActive ? $theme('layout.sidebar_link_active') : '']"
        >
            <i :class="[$theme('layout.sidebar_link_icon', icon)]"></i>
            <span
                :class="$theme('layout.sidebar_link_text')"
                v-if="!collapsed"
            >
                {{ label }}
            </span>
        </NuxtLink>
    </div>
</template>
