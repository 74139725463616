<script setup lang="ts">
    const props = defineProps({
  name: null,
  title: null,
  text: null,
  button: null,
  plan: null
});

    useModal(props.name);

    const close = () => useEvent(`${props.name}:close`);

    const benefits = computed(() => {
        if ( props.plan == 'pro' ) {
            return [
                'composed_works',
                'worksite_closure_reports',
                // 'bank_reconciliation',
                // 'expenses',
                'planning',
                'crm'
            ];
        }

        return [];
    });
</script>

<template>
    <Modal :name="name" :width="1000">
        <div class="flex items-stretch w-full min-h-[600px]">
            <div class="p-8 bg-primary-pastel-100 w-[400px] rounded-l-lg flex flex-col">
                <div class="flex justify-center w-full">
                    <img src="~/assets/logo.svg" alt="Logo" class="h-[30px]" />
                </div>
                <div class="flex items-center flex-1">
                    <div class="flex flex-col space-y-4">
                        <p class="text-base text-primary-dark">
                            {{ $t('upsell_modal.benefits.title', {plan}) }}
                        </p>
                        <div class="flex items-center space-x-4" v-for="benefit in benefits" :key="benefit">
                            <i class="text-base fa-regular fa-check text-primary-dark" />
                            <span class="text-xs text-primary-dark">
                                {{ $t(`upsell_modal.benefits.${benefit}`) }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex items-center justify-center flex-1">
                <div class="p-8">
                    <h2 :class="$theme('modal.title.text', 'mb-4')">
                        {{ title }}
                    </h2>
                    <p class="mb-16 text-sm text-center text-neutral-600">
                        {{ text }}
                    </p>
                    <div class="flex justify-center w-full space-x-4">
                        <LoadingButton :class-name="$theme('button.style.default_pastel')" @clicked="close()">
                            {{ $t('actions.cancel') }}
                        </LoadingButton>
                        <LoadingButton @clicked="navigateTo('/settings/subscription'); close();">
                            {{ button }}
                        </LoadingButton>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>