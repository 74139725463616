<script setup lang="ts">
    import moment from '~~/utils/moment';
    import TimeActivity from '@/app/planning/models/TimeActivity';
    import Form from '@/classes/Form';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import TimeActivitySchema from '~~/app/planning/schemas/TimeActivitySchema';
    import User from '~~/app/auth/models/User';
    import { useEvent } from '@/composables/useEventBus';
    import TimeActivityEndpoint from '~~/app/planning/endpoints/TimeActivityEndpoint';
    import Worksite from '~~/app/worksite/models/Worksite';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import WorksitePhase from '~~/app/worksite/models/WorksitePhase';
    import Task from '~~/app/task/models/Task';

    interface Fields {
        title: string;
        user: User | null;
        worksite: Worksite | null;
        worksitePhase: WorksitePhase | null;
        task: Task | null;
        startsAt: string;
        endsAt: string | null;
    }
    const form = useForm<Fields>(
        {
            title: '',
            user: null,
            worksite: null,
            worksitePhase: null,
            task: null,
            startsAt: moment().hour(8).startOf('hour').toDateTimeString(),
            endsAt: moment().hour(16).startOf('hour').toDateTimeString(),
        },
        (field: string, value: any) => {
            if (field === 'worksite') {
                form.setSilently('worksitePhase', null);
                form.setSilently('task', null);
            }
            if (field === 'worksitePhase') {
                form.setSilently('task', null);
            }

            if (field === 'startsAt' || field === 'endsAt') {
                const endsAt = moment(form.fields.endsAt);
                endsAt.date(moment(form.get('startsAt')).date());
                form.fields.endsAt = endsAt.toDateTimeString();

                if (moment(form.fields.startsAt).isSameOrAfter(form.fields.endsAt)) {
                    form.set('endsAt', moment(form.fields.startsAt).add(1, 'hour').toDateTimeString());
                }
            }
        }
    );
    const { formattedDuration } = useDateRangeForm(form, 'startsAt', 'endsAt');

    const { modalName, model } = useModelFormModal<TimeActivity, Fields>(
        TimeActivity,
        (payload?: FormModalPayload<TimeActivity, Fields>) => fillForm(payload),
        () => form.reset()
    );

    const fillForm = (payload?: FormModalPayload<TimeActivity, Fields>) => {
        if (payload?.model) {
            form.fill({
                title: payload?.model.title,
                user: payload?.model.user,
                worksite: payload?.model.worksite,
                worksitePhase: payload?.model.worksitePhase,
                task: payload?.model.task,
                startsAt: payload?.model.startsAt?.toDateTimeString() || '',
                endsAt: payload?.model.endsAt?.toDateTimeString() || '',
            });
        }
    };

    const submit = async () => {
        // format date to utc as back end give an utc date (the date where incrementing for no reason)
        form.fields.endsAt = moment(form.fields.endsAt).utc().toDateTimeString();
        form.fields.startsAt = moment(form.fields.startsAt).utc().toDateTimeString();
        const schema = TimeActivitySchema.make({
            attributes: form.dataExcept(['user', 'worksite', 'worksitePhase', 'task']),
        });
        if (form.get('user')) {
            schema.addToOneRelationship('user', ModelType.USERS, form.get('user').getId());
        }
        if (form.get('worksite')) {
            schema.addToOneRelationship('worksite', ModelType.WORKSITES, form.get('worksite').getId());
        }
        if (form.get('worksitePhase')) {
            schema.addToOneRelationship('worksitePhase', ModelType.WORKSITE_PHASES, form.get('worksitePhase').getId());
        }
        if (form.get('task')) {
            schema.addToOneRelationship('task', ModelType.TASKS, form.get('task').getId());
        }

        if (model.value) {
            schema.id = model.value?.getId();
        }

        const endpoint: TimeActivityEndpoint = EndpointFactory.make(ModelType.TIME_ACTIVITIES) as TimeActivityEndpoint;
        const response = await form.loadUntil(endpoint.storeOrUpdate(schema));

        if (response.error) {
            if (!response.validationErrors) {
                useToasteoError();
            }
            return;
        }

        close();
    };

    const close = () => {
        form.reset();
        useEvent(`${modalName}:close`);
    };
</script>

<template>
    <ModelFormModal
        :model="TimeActivity"
        :scrollable="true"
        @before-close="form.reset()"
    >
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text')">
                {{ model ? $t('planning.time_activity.edit_form.title') : $t('planning.time_activity.create_form.title') }}
            </h2>
        </div>
        <form @submit.prevent="submit">
            <div :class="$theme('modal.padding')">
                <FormWorksiteSelect
                    :form="form"
                    input-name="worksite"
                    @changed="
                        form.set('worksitePhase', null);
                        form.set('task', null);
                    "
                />
                <div
                    v-if="form.get('worksite')"
                    :class="{
                        'flex justify-stretch space-x-4': form.get('worksitePhase'),
                    }"
                >
                    <div class="flex-1">
                        <FormWorksitePhaseSelect
                            :form="form"
                            input-name="worksitePhase"
                            :worksite="form.get('worksite')"
                            @changed="form.set('worksitePhase', null)"
                        />
                    </div>
                    <div
                        v-if="form.get('worksitePhase')"
                        class="flex-1"
                    >
                        <FormTaskSelect
                            :form="form"
                            input-name="task"
                            :worksite-phase="form.get('worksitePhase')"
                            @changed="form.set('task', null)"
                        />
                    </div>
                </div>

                <FormUserSelect
                    :form="form"
                    input-name="user"
                />

                <FormInput
                    :form="form"
                    input-name="title"
                    autofocus
                />

                <div :class="$theme('form.containers.element')">
                    <div class="flex items-center justify-between space-x-6">
                        <div>
                            <span :class="$theme('form.label')">
                                {{ $t('form.labels.start_end_date') }}
                            </span>
                            <div class="flex items-center space-x-2">
                                <FormDate
                                    :form="form"
                                    input-name="startsAt"
                                    alignment="bottom-full"
                                    position="left-0"
                                    without-label
                                />
                                <FormTime
                                    :form="form"
                                    input-name="startsAt"
                                    alignment="bottom-full"
                                    position="left-0"
                                    without-label
                                />
                                <FormTime
                                    :form="form"
                                    input-name="endsAt"
                                    alignment="bottom-full"
                                    position="left-0"
                                    without-label
                                    :min-time="form.get('startsAt')"
                                />
                            </div>
                        </div>
                        <div>
                            <span
                                class="text-center"
                                :class="$theme('form.label')"
                                >{{ $t('form.labels.totalTime') }}</span
                            >
                            <span class="text-2xl font-medium text-gray-800">
                                {{ formattedDuration }}
                            </span>
                        </div>
                    </div>
                </div>
                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="close()"
                        >{{ $t('actions.cancel') }}</LoadingButton
                    >
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :loading="form.isLoading"
                        type="submit"
                        >{{ $t('actions.save') }}</LoadingButton
                    >
                </div>
            </div>
        </form>
    </ModelFormModal>
</template>
