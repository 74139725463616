
<script lang="ts" setup></script>

<template>
    <CommonUpsellModal 
        name="invoicing:composed-work:upsell-modal"
        plan="pro"
        :title="$t('invoicing.composed_work.upsell_modal.title')"
        :text="$t('invoicing.composed_work.upsell_modal.text')"
        :button="$t('invoicing.composed_work.upsell_modal.button')"
    />
</template>
