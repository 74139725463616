<script setup lang="ts">
    import { useSortable } from '@vueuse/integrations/useSortable';
    import InvoiceTemplate from '@/app/invoicing/models/InvoiceTemplate';
    import InvoiceTemplateSchema from '@/app/invoicing/schemas/InvoiceTemplateSchema';
    import { ModelType } from '@/app/base/schemas/BaseSchema';
    import InvoiceTemplateSection from '@/app/invoicing/models/InvoiceTemplateSection';
    import EndpointFactory from '@/app/factories/EndpointFactory';
    import InvoiceTemplateSectionSchema from '@/app/invoicing/schemas/InvoiceTemplateSectionSchema';
    import InvoiceTemplateSectionEndpoint from '@/app/invoicing/endpoints/InvoiceTemplateSectionEndpoint';

    const { enable, disable } = useUploadDropZoneStore();
    interface Fields {
        title: string;
        default: boolean;
        locale: string;
        documentType: string | null;
        headerImageRepeat: boolean;
        headerImageUrl: string | null;
        headerImageHeight: number | null;
        headerImageWidth: number | null;
        footerImageUrl: string | null;
        footerImageHeight: number | null;
        footerImageWidth: number | null;
        footerText: string | null;
        logoStyle: object;
        tableColumns: object;
        tableStyle: object;
        invoiceTemplateSections: InvoiceTemplateSection[];
    }

    const loading = ref(true);
    const preview = ref({
        loading: false,
        html: '',
    });
    const translation = useTranslation();
    const documentTypes = useGetInvoiceDocumentTypes();
    const form = useForm<Fields>({
        title: '',
        default: false,
        locale: translation.getCurrentLocale(),
        documentType: null,
        headerImageRepeat: false,
        headerImageUrl: null,
        headerImageHeight: null,
        headerImageWidth: null,
        footerImageUrl: null,
        footerImageHeight: null,
        footerImageWidth: null,
        footerText: null,
        logoStyle: {
            marginBottom: null,
            width: null,
            height: null,
        },
        tableStyle: {
            displayFirstSectionEvenIfAlone: false,
            displayHeadingsBeforeSection: false,
            displaySectionSubtotalUnderSection: false,
            sectionBackgroundColor: null,
            sectionTextColor: null,
            totalSubtotalBackgroundColor: null,
            totalSubtotalTextColor: null,
            totalVatBackgroundColor: null,
            totalVatTextColor: null,
            totalTotalBackgroundColor: null,
            totalTotalTextColor: null,
        },
        tableColumns: {
            image: {
                display: false,
                label: null,
                style: {
                    width: 80,
                    textColor: null,
                    textBackgroundColor: null,
                    headingColor: null,
                    headingBackgroundColor: null,
                    textAlign: 'left',
                },
            },
            title: {
                display: true,
                label: null,
                style: {
                    width: null,
                    textColor: null,
                    textBackgroundColor: null,
                    headingColor: null,
                    headingBackgroundColor: null,
                    textAlign: 'left',
                },
            },
            description: {
                display: true,
                label: null,
                style: {
                    width: null,
                    textColor: null,
                    textBackgroundColor: null,
                    headingColor: null,
                    headingBackgroundColor: null,
                    textAlign: 'left',
                },
            },
            quantity: {
                display: true,
                label: null,
                style: {
                    width: null,
                    textColor: null,
                    textBackgroundColor: null,
                    headingColor: null,
                    headingBackgroundColor: null,
                    textAlign: 'left',
                },
            },
            price: {
                display: true,
                label: null,
                style: {
                    width: null,
                    textColor: null,
                    textBackgroundColor: null,
                    headingColor: null,
                    headingBackgroundColor: null,
                    textAlign: 'left',
                },
            },
            discount: {
                display: true,
                label: null,
                style: {
                    width: null,
                    textColor: null,
                    textBackgroundColor: null,
                    headingColor: null,
                    headingBackgroundColor: null,
                    textAlign: 'left',
                },
            },
            vat: {
                display: true,
                label: null,
                style: {
                    width: null,
                    textColor: null,
                    textBackgroundColor: null,
                    headingColor: null,
                    headingBackgroundColor: null,
                    textAlign: 'left',
                },
            },
            subtotal: {
                display: true,
                label: null,
                style: {
                    width: null,
                    textColor: null,
                    textBackgroundColor: null,
                    headingColor: null,
                    headingBackgroundColor: null,
                    textAlign: 'left',
                },
            },
        },
        invoiceTemplateSections: [],
    });

    const { modalName, model } = useModelFormModal<InvoiceTemplate, Fields>(
        InvoiceTemplate,
        async (payload?: FormModalPayload<InvoiceTemplate, Fields>) => {
            disable();
            if (payload?.model) {
                const { load, model } = useModelLoader<InvoiceTemplate>(ModelType.INVOICE_TEMPLATES, payload?.model.getId(), () => {
                    return {
                        include: 'invoiceTemplateSections,invoiceTemplateSections.invoiceTemplateSectionColumns',
                    };
                });
                await load();
                form.fillWithModel(model.value);
                console.log(model.value, 'MODEL');
                await generatePreview();
            }
            loading.value = false;
            await nextTick();
            setupSectionsSortable();
        },
        () => {
            enable();
        }
    );

    const displayedSections = ref<string[]>([]);
    const sectionIsDisplayed = (section: string) => {
        return displayedSections.value.includes(section);
    };
    const toggleSection = (section: string) => {
        sectionIsDisplayed(section) ? (displayedSections.value = displayedSections.value.filter((item) => item !== section)) : displayedSections.value.push(section);
    };

    const generatePreview = async () => {
        preview.value.loading = true;
        const endpoint = EndpointFactory.make(ModelType.INVOICE_TEMPLATES);
        const response = await endpoint.preview(model.value.getId());
        preview.value.html = response.data.attributes.html;
        preview.value.loading = false;
    };

    const submit = async () => {
        const schema = InvoiceTemplateSchema.make({
            attributes: form.dataExcept(['invoiceTemplateSections']),
            id: model.value?.getId(),
        });

        schema.attributes.tableStyle.displayFirstSectionEvenIfAlone = !!form.get('tableStyle.displayFirstSectionEvenIfAlone');
        schema.attributes.tableStyle.displayHeadingsBeforeSection = !!form.get('tableStyle.displayHeadingsBeforeSection');
        schema.attributes.tableStyle.displaySectionSubtotalUnderSection = !!form.get('tableStyle.displaySectionSubtotalUnderSection');

        const response = await form.loadUntil(useStoreOrUpdateModel(ModelType.INVOICE_TEMPLATES, schema));
        if (response.error) {
            useToasteoError();
            return;
        }
        useToasteoSuccess();
        generatePreview();
    };

    useListen('invoicing:invoice-template-sections:created', () => generatePreview());
    useListen('invoicing:invoice-template-sections:updated', () => generatePreview());
    useListen('invoicing:invoice-template-sections:deleted', () => generatePreview());
    useListen('invoicing:invoice-template-section-columns:created', () => generatePreview());
    useListen('invoicing:invoice-template-section-columns:updated', () => generatePreview());
    useListen('invoicing:invoice-template-section-columns:deleted', () => generatePreview());

    const setupSectionsSortable = () => {
        if (!sectionsSortableContainer.value) {
            return;
        }

        useSortable(sectionsSortableContainer, form.get('invoiceTemplateSections'), {
            animation: 150,
            handle: '.js-invoice-template-section-handle',
            group: 'invoice-template-sections',
            onEnd: async (event) => {
                preview.value.loading = true;
                const invoiceTemplateSection = form.get('invoiceTemplateSections').findById(event.item.dataset.id);
                await saveInvoiceTemplateSectionOrder(invoiceTemplateSection, event.newIndex);
                generatePreview();
                // await nextTick();
                // console.log(event, form.get('invoiceTemplateSections').map((section) => section.title), form.get('invoiceTemplateSections').map((section) => section.order));
                // await saveInvoiceTemplateSectionsOrder();
                // generatePreview();
            },
        });
    };

    const saveInvoiceTemplateSectionsOrder = async () => {
        const promises = [];
        form.get('invoiceTemplateSections').forEach((section, index) => {
            promises.push(saveInvoiceTemplateSectionOrder(section, index));
        });

        await Promise.all(promises);
    };

    const saveInvoiceTemplateSectionOrder = async (section, index) => {
        const schema = InvoiceTemplateSectionSchema.make({
            attributes: {
                order: index + 1,
                beforeTable: !!section.beforeTable,
            },
            id: section.getId(),
        });
        await useStoreOrUpdateModel(ModelType.INVOICE_TEMPLATE_SECTIONS, schema, (endpoint: InvoiceTemplateSectionEndpoint) => endpoint.withoutEvents());
    };

    const sectionsSortableContainer = ref<HTMLElement>();
    onMounted(() => {
        setupSectionsSortable();
    });

    const close = () => {
        form.reset();
        useEvent(`${modalName}:close`);
    };
</script>

<template>
    <ModelFormModal
        :model="InvoiceTemplate"
        modal-style="right_panel_full"
        @before-close="form.reset()"
    >
        <Loader v-if="loading" />
        <div
            v-else
            class="flex items-stretch w-full h-full"
        >
            <div class="w-[300px] lg:w-[400px] 2xl:w-[500px] h-full flex flex-col h-full divide-y divide-gray-100 overflow-y-scroll nice-scrollbar">
                <div :class="$theme('modal.padding')">
                    <form @submit.prevent="submit">
                        <div
                            class="flex items-center justify-between mb-4 cursor-pointer"
                            @click.prevent="toggleSection('information')"
                        >
                            <p class="text-neutral-800">
                                {{ $t('form.labels.information') }}
                            </p>
                            <span>
                                <i
                                    class="text-xs transition duration-100 rotate-0 text-neutral-400 group-hover:text-black fa-solid dark:text-neutral-300 fa-chevron-down"
                                    :class="{
                                        'rotate-180': !sectionIsDisplayed('information'),
                                    }"
                                />
                            </span>
                        </div>
                        <template v-if="sectionIsDisplayed('information')">
                            <FormTwoFields
                                container-class="flex items-center space-x-4"
                                field-2-class="w-auto"
                            >
                                <template #field-1>
                                    <FormInput
                                        :form="form"
                                        input-name="title"
                                    />
                                </template>
                                <template #field-2>
                                    <div class="mt-5">
                                        <FormCheckbox
                                            :form="form"
                                            input-name="default"
                                        />
                                    </div>
                                </template>
                            </FormTwoFields>
                            <FormTwoFields>
                                <template #field-1>
                                    <FormSelect
                                        :form="form"
                                        input-name="locale"
                                    >
                                        <option value="">
                                            {{ $t('form.options.apply_to_all_locales') }}
                                        </option>
                                        <option
                                            v-for="locale in useAvailableLocales()"
                                            :key="locale"
                                            :value="locale"
                                        >
                                            {{ $t(`locales.${locale}`) }}
                                        </option>
                                    </FormSelect>
                                </template>
                                <template #field-2>
                                    <FormSelect
                                        :form="form"
                                        input-name="documentType"
                                    >
                                        <option value="">
                                            {{ $t('form.options.apply_to_document_types') }}
                                        </option>
                                        <option
                                            v-for="documentType in documentTypes"
                                            :key="documentType"
                                            :value="documentType"
                                        >
                                            {{ $t(`invoice_document_type.${documentType}`) }}
                                        </option>
                                    </FormSelect>
                                </template>
                            </FormTwoFields>
                        </template>
                        <div
                            class="flex items-center justify-between mb-4 cursor-pointer"
                            @click.prevent="toggleSection('logoStyle')"
                        >
                            <p class="text-neutral-800">
                                {{ $t('form.labels.logoStyle') }}
                            </p>
                            <span>
                                <i
                                    class="text-xs transition duration-100 rotate-0 text-neutral-400 group-hover:text-black fa-solid dark:text-neutral-300 fa-chevron-down"
                                    :class="{
                                        'rotate-180': !sectionIsDisplayed('logoStyle'),
                                    }"
                                />
                            </span>
                        </div>
                        <template v-if="sectionIsDisplayed('logoStyle')">
                            <div class="mb-4 text-neutral-800">
                                <div
                                    v-if="model?.meta.companyLogo !== null"
                                    class="flex items-center px-4 py-3 space-x-4 rounded bg-neutral-100"
                                >
                                    <div>
                                        <i class="text-lg fa-regular fa-circle-info" />
                                    </div>
                                    <div class="text-xs text-neutral-600">
                                        <p>Your company logo is currently {{ model.meta.companyLogo.width }}px wide and {{ model.meta.companyLogo.height }}px high.</p>
                                        <p>We recommend using sizes that matches this aspect ratio, otherwise the logo will be placed in the center of the box created.</p>
                                    </div>
                                </div>
                            </div>
                            <FormInput
                                :form="form"
                                input-name="logoStyle.marginBottom"
                                input-type="number"
                            />
                            <FormInput
                                :form="form"
                                input-name="logoStyle.width"
                                input-type="number"
                            />
                            <FormInput
                                :form="form"
                                input-name="logoStyle.height"
                                input-type="number"
                            />
                        </template>
                        <div
                            class="flex items-center justify-between mb-4 cursor-pointer"
                            @click.prevent="toggleSection('tableStyle')"
                        >
                            <p class="text-neutral-800">
                                {{ $t('form.labels.tableStyle') }}
                            </p>
                            <span>
                                <i
                                    class="text-xs transition duration-100 rotate-0 text-neutral-400 group-hover:text-black fa-solid dark:text-neutral-300 fa-chevron-down"
                                    :class="{
                                        'rotate-180': !sectionIsDisplayed('tableStyle'),
                                    }"
                                />
                            </span>
                        </div>
                        <template v-if="sectionIsDisplayed('tableStyle')">
                            <FormCheckbox
                                :form="form"
                                input-name="tableStyle.displayFirstSectionEvenIfAlone"
                            />
                            <FormCheckbox
                                :form="form"
                                input-name="tableStyle.displayHeadingsBeforeSection"
                            />
                            <FormCheckbox
                                :form="form"
                                input-name="tableStyle.displaySectionSubtotalUnderSection"
                            />
                            <template
                                v-for="sectionType in ['', 'heading', 'section', 'line']"
                                :key="`table-style-border-${sectionType}-inputs`"
                            >
                                <template
                                    v-for="position in ['vertical', 'horizontal']"
                                    :key="`table-style-border-${sectionType}-${position}-inputs`"
                                >
                                    <FormInput
                                        :form="form"
                                        :input-name="`tableStyle.tableBorder${sectionType.capitalize()}${position.capitalize()}SeparatorWidth`"
                                        input-type="number"
                                        :min="0"
                                        :max="10"
                                    />
                                    <FormInput
                                        :form="form"
                                        :input-name="`tableStyle.tableBorder${sectionType.capitalize()}${position.capitalize()}SeparatorColor`"
                                    />
                                </template>
                            </template>
                            <FormInput
                                :form="form"
                                input-name="tableStyle.headingFontSize"
                                input-type="number"
                            />
                            <FormInput
                                :form="form"
                                input-name="tableStyle.headingFontWeight"
                                input-type="number"
                            />
                            <FormInput
                                :form="form"
                                input-name="tableStyle.sectionPaddingHorizontal"
                                input-type="number"
                            />
                            <FormInput
                                :form="form"
                                input-name="tableStyle.sectionPaddingVertical"
                                input-type="number"
                            />
                            <FormInput
                                :form="form"
                                input-name="tableStyle.sectionBackgroundColor"
                            />
                            <FormInput
                                :form="form"
                                input-name="tableStyle.sectionTextColor"
                            />
                            <FormInput
                                :form="form"
                                input-name="tableStyle.sectionFontSize"
                                input-type="number"
                            />
                            <FormInput
                                :form="form"
                                input-name="tableStyle.sectionFontWeight"
                                input-type="number"
                            />
                            <FormInput
                                :form="form"
                                input-name="tableStyle.lineFontSize"
                                input-type="number"
                            />
                            <FormInput
                                :form="form"
                                input-name="tableStyle.lineFontWeight"
                                input-type="number"
                            />
                            <FormInput
                                :form="form"
                                input-name="tableStyle.totalSubtotalBackgroundColor"
                            />
                            <FormInput
                                :form="form"
                                input-name="tableStyle.totalSubtotalTextColor"
                            />
                            <FormInput
                                :form="form"
                                input-name="tableStyle.totalSubtotalFontSize"
                                input-type="number"
                            />
                            <FormInput
                                :form="form"
                                input-name="tableStyle.totalSubtotalFontWeight"
                                input-type="number"
                            />
                            <FormInput
                                :form="form"
                                input-name="tableStyle.totalVatBackgroundColor"
                            />
                            <FormInput
                                :form="form"
                                input-name="tableStyle.totalVatTextColor"
                            />
                            <FormInput
                                :form="form"
                                input-name="tableStyle.totalVatFontSize"
                                input-type="number"
                            />
                            <FormInput
                                :form="form"
                                input-name="tableStyle.totalVatFontWeight"
                                input-type="number"
                            />
                            <FormInput
                                :form="form"
                                input-name="tableStyle.totalTotalBackgroundColor"
                            />
                            <FormInput
                                :form="form"
                                input-name="tableStyle.totalTotalTextColor"
                            />
                            <FormInput
                                :form="form"
                                input-name="tableStyle.totalTotalFontSize"
                                input-type="number"
                            />
                            <FormInput
                                :form="form"
                                input-name="tableStyle.totalTotalFontWeight"
                                input-type="number"
                            />
                        </template>
                        <div
                            class="flex items-center justify-between mb-4 cursor-pointer"
                            @click.prevent="toggleSection('tableColumns')"
                        >
                            <p class="text-neutral-800">
                                {{ $t('form.labels.tableColumns') }}
                            </p>
                            <span>
                                <i
                                    class="text-xs transition duration-100 rotate-0 text-neutral-400 group-hover:text-black fa-solid dark:text-neutral-300 fa-chevron-down"
                                    :class="{
                                        'rotate-180': !sectionIsDisplayed('tableColumns'),
                                    }"
                                />
                            </span>
                        </div>
                        <template v-if="sectionIsDisplayed('tableColumns')">
                            <div class="flex flex-col space-y-2">
                                <div
                                    v-for="key in model.meta.tableColumns.keys"
                                    :key="`table-column-${$key}`"
                                >
                                    <div class="flex items-center mb-2 space-x-4">
                                        <p class="text-sm text-neutral-600">
                                            {{ $t(`form.labels.${key}`) }}
                                        </p>
                                        <FormCheckbox
                                            :form="form"
                                            :input-name="`tableColumns.${key}.display`"
                                            element-class-name=" "
                                        />
                                    </div>
                                    <template v-if="form.get(`tableColumns.${key}.display`)">
                                        <FormInput
                                            :form="form"
                                            :input-name="`tableColumns.${key}.label`"
                                        />
                                        <FormInput
                                            :form="form"
                                            :input-name="`tableColumns.${key}.order`"
                                            input-type="number"
                                        />
                                        <FormInput
                                            :form="form"
                                            :input-name="`tableColumns.${key}.style.width`"
                                            input-type="number"
                                        />
                                        <FormInput
                                            :form="form"
                                            :input-name="`tableColumns.${key}.style.headingColor`"
                                        />
                                        <FormInput
                                            :form="form"
                                            :input-name="`tableColumns.${key}.style.headingBackgroundColor`"
                                        />
                                        <FormInput
                                            :form="form"
                                            :input-name="`tableColumns.${key}.style.textColor`"
                                        />
                                        <FormInput
                                            :form="form"
                                            :input-name="`tableColumns.${key}.style.textBackgroundColor`"
                                        />
                                        <FormSelect
                                            :form="form"
                                            :input-name="`tableColumns.${key}.style.textAlign`"
                                        >
                                            <option
                                                v-for="align in ['left', 'right', 'center']"
                                                :key="align"
                                                :value="align"
                                            >
                                                {{ $t(`align.${align}`) }}
                                            </option>
                                        </FormSelect>
                                    </template>
                                </div>
                            </div>
                        </template>
                        <div
                            class="flex items-center justify-between mb-4 cursor-pointer"
                            @click.prevent="toggleSection('headerFooterImages')"
                        >
                            <p class="text-neutral-800">
                                {{ $t('form.labels.headerFooterImages') }}
                            </p>
                            <span>
                                <i
                                    class="text-xs transition duration-100 rotate-0 text-neutral-400 group-hover:text-black fa-solid dark:text-neutral-300 fa-chevron-down"
                                    :class="{
                                        'rotate-180': !sectionIsDisplayed('headerFooterImages'),
                                    }"
                                />
                            </span>
                        </div>
                        <template v-if="sectionIsDisplayed('headerFooterImages')">
                            <FormCheckbox
                                :form="form"
                                input-name="headerImageRepeat"
                            />
                            <InvoicingInvoiceTemplateFormModalImageInput
                                v-for="block in ['header', 'footer']"
                                :key="block"
                                :invoice-template="model"
                                :form="form"
                                :block="block"
                            />
                            <FormEditor
                                :form="form"
                                input-name="footerText"
                            />
                        </template>
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :loading="form.isLoading"
                            type="submit"
                        >
                            {{ $t('actions.save_information') }}
                        </LoadingButton>
                    </form>
                </div>
                <div
                    v-if="model"
                    :class="$theme('modal.padding')"
                >
                    <p class="mb-4 text-neutral-800">
                        {{ $t('form.labels.content') }}
                    </p>
                    <div
                        ref="sectionsSortableContainer"
                        class="flex flex-col space-y-2"
                    >
                        <div
                            v-for="invoiceTemplateSection in form.get('invoiceTemplateSections')"
                            :key="invoiceTemplateSection.getInternalId()"
                            class="flex items-center justify-between p-2 text-sm transition-all border border-gray-100 rounded cursor-pointer text-neutral-600 bg-neutral-100 hover:bg-white"
                            :data-id="invoiceTemplateSection.getId()"
                            @click.prevent="invoiceTemplateSection.edit({ fields: { invoiceTemplate: model } })"
                            @contextmenu="invoiceTemplateSection.openContextMenu($event)"
                        >
                            <div class="flex items-center space-x-4">
                                <span class="js-invoice-template-section-handle"><i class="fa-solid fa-grip-dots-vertical"></i></span>
                                <span>{{ invoiceTemplateSection.title ?? $t('misc.no_title') }}</span>
                            </div>
                            <span class="text-xs">
                                {{ invoiceTemplateSection.beforeTable ? $t('misc.invoice_template.before_table') : $t('misc.invoice_template.after_table') }}
                            </span>
                        </div>
                        <LoadingButton
                            :class-name="$theme('button.style.white_border_sm2')"
                            @clicked="useEvent('invoicing:invoice-template-sections:form-modal:open', { fields: { invoiceTemplate: model } })"
                        >
                            <i class="mr-1 text-xs fa-regular fa-plus"></i>
                            <span>
                                {{ $t('actions.add_section') }}
                            </span>
                        </LoadingButton>
                    </div>
                </div>
            </div>
            <div :class="$theme('modal.padding', 'flex-1 bg-neutral-100 relative flex justify-center h-full overflow-y-scroll nice-scrollbar')">
                <div v-if="model">
                    <Loader
                        v-if="preview.loading"
                        background-color="bg-neutral-100"
                    />
                    <iframe
                        v-else
                        :srcdoc="preview.html"
                        class="w-[796px] h-[1122px] mb-4 border border-gray-200 rounded bg-white overflow-y-scroll nice-scrollbar"
                    ></iframe>
                </div>
                <div v-else>
                    {{ $t('misc.invoice_template.please_save_to_see_preview') }}
                </div>
            </div>
        </div>
    </ModelFormModal>
</template>
