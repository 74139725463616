<script setup lang="ts">
    import { UnwrapNestedRefs } from 'nuxt/dist/app/compat/capi';
    import ImportedFileEndpoint from '../../../app/common/endpoints/ImportedFileEndpoint';
    import ImportedFile from '../../../app/common/models/ImportedFile';
    import { Importable } from '../../../app/common/enums/Importable';
    import Contact from '../../../app/contact/models/Contact';
    import { ContactType } from '../../../app/contact/enums/ContactType';
    import ImportUpload from './ImportUpload.vue';
    import ImportSample from './ImportSample.vue';
    import Product from '@/app/inventory/models/Product';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import { useEvent } from '@/composables/useEventBus';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';

    const loading = ref(true);
    export interface Fields {
        isSupplier: boolean;
    }
    const form = useForm<Fields>({
        isSupplier: false,
    });

    const { modalName, model } = useModal('contact:contact:import-modal', (payload?: UnwrapNestedRefs<Product>) => onOpened(payload));

    const contactQueryType = ref();
    const router = useRouter();
    const route = useRoute();
    const getUrlQueryParams = async () => {
        // router is async so we wait for it to be ready
        await router.isReady();
        // once its ready we can access the query params
        contactQueryType.value = route.query.tab || 'client';
    };
    const onOpened = async (payload?: UnwrapNestedRefs<Contact>) => {
        await getUrlQueryParams();
        resetErros();
        form.reset();
        form.set('isSupplier', contactQueryType.value !== 'client');
        loading.value = true;
        file.value = undefined;
        shouldDisplay.value = true;
        isUploadable.value = false;
        inputLabel.value = t('actions.upload');
        loading.value = false;
    };

    const shouldDisplay = ref<boolean>(true);

    const toggle = () => {
        shouldDisplay.value = !shouldDisplay.value;
    };

    const { t } = useTranslation();
    const file = ref<File>();
    const inputLabel = ref<string>(t('actions.upload'));
    const fileChanged = ($event: Event) => {
        const target = $event.target as HTMLInputElement;
        if (target && target.files) {
            file.value = target.files[0];
        }
        if (file?.value?.name) inputLabel.value = file.value.name;
    };

    const isUploadable = ref<boolean>(false);
    const isRequesting = ref<boolean>(false);
    const importedFile = ref<ImportedFile | null>();
    const rawErrors = ref();
    const errors = ref();

    const getRowsCells = () => {
        errors.value = Object.entries(rawErrors.value).map(([key, errorMessages]) => {
            const keyParts = key.split('.');
            const row = keyParts[1];
            const cell = keyParts[2];
            return { row, cell, message: errorMessages[0] };
        });
    };
    const resetErros = () => {
        errors.value = null;
        rawErrors.value = null;
    };

    const validate = async () => {
        resetErros();
        isRequesting.value = true;
        isUploadable.value = false;
        const endpoint: ImportedFileEndpoint = EndpointFactory.make(ModelType.IMPORTED_FILE) as ImportedFileEndpoint;
        const contactType = form.get('isSupplier') ? ContactType.SUPPLIER : ContactType.CLIENT;
        const response = await endpoint.validated(file.value as File, 'clients', contactType);
        if (response.error) {
            if (!response.validationErrors) {
                isUploadable.value = false;
                isRequesting.value = false;
                rawErrors.value = response.error;
                getRowsCells();
            }
            return;
        }
        importedFile.value = response.data;
        isRequesting.value = false;
        isUploadable.value = true;

        useToasteoSuccess();
    };

    const isUploading = ref<boolean>(false);
    const upload = async () => {
        isUploading.value = true;
        const endpoint: ImportedFileEndpoint = EndpointFactory.make(ModelType.IMPORTED_FILE) as ImportedFileEndpoint;
        const contactType = form.get('isSupplier') ? ContactType.SUPPLIER : ContactType.CLIENT;
        const response = await endpoint.upload(importedFile.value?.getId() as string, Importable.CLIENT, contactType);

        if (response.error) {
            if (!response.validationErrors) {
                useToasteoError();
                isUploading.value = false;
            }
            return;
        }
        isUploading.value = false;
        useToasteoSuccess();
        close();
        return navigateTo({ name: 'invoicing-import' });
    };

    const close = () => {
        // form.reset();
        resetErros();

        useEvent(`${modalName}:close`);
    };
</script>

<template>
    <Modal
        ref="importModal"
        name="contact:contact:import-modal"
        :width="1000"
    >
        <ModalLoader v-if="loading"></ModalLoader>
        <template v-else>
            <div :class="$theme('modal.title.container')">
                <h2
                    :class="$theme('modal.title.text')"
                    class="pt-4 text-center first-letter:capitalize whitespace-nowrap"
                >
                    {{ form.get('isSupplier') ? $t('contact.supplier.import_form.title') : $t('contact.contact.import_form.title') }}
                </h2>
            </div>
            <div
                :class="$theme('modal.padding')"
                class="space-y-4 text-center"
            >
                <ImportSample
                    v-if="shouldDisplay"
                    @close="close()"
                    @continue="toggle()"
                />
                <ImportUpload
                    v-else-if="!shouldDisplay && !isUploadable"
                    :file="file"
                    :label="inputLabel"
                    :loading="isRequesting"
                    :form="form"
                    @previous="toggle()"
                    @validate="validate()"
                    @file-changed="($event) => fileChanged($event)"
                >
                    <div
                        v-if="rawErrors"
                        class="w-full p-4 mb-4 text-sm text-white bg-red-600 rounded text-left space-y-2 max-h-[500px] overflow-y-scroll"
                    >
                        <p>{{ $t('toasts.error') }}</p>
                        <div>
                            <p
                                v-for="(error, index) in errors"
                                :key="index"
                                class=""
                            >
                                <span class="mr-2">{{ $t('import_form.error_line_number', { number: error.row }) }}:</span>
                                <span>
                                    {{ error.message }}
                                </span>
                            </p>
                        </div>
                    </div>
                </ImportUpload>
                <ContactContactImportPreview
                    v-if="isUploadable"
                    :loading="isUploading"
                    :have-file-selected="!file"
                    :imported-file="importedFile"
                    @previous="isUploadable = !isUploadable"
                    @upload="upload()"
                />
            </div>
        </template>
    </Modal>
</template>
