<script setup lang="ts">
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import InvoiceCollection from '~~/app/invoicing/collections/InvoiceCollection';
    import InvoiceEndpoint from '~~/app/invoicing/endpoints/InvoiceEndpoint';
    import Invoice from '~~/app/invoicing/models/Invoice';

    interface PayloadInterface {
        invoice: Invoice;
    }

    const endpoint = EndpointFactory.make(ModelType.INVOICES) as InvoiceEndpoint;
    const invoice = reactiveModel<Invoice>(null);
    const finalInvoice = reactiveModel<Invoice>(null);
    const checkedDepositInvoices = ref<string[]>([]);
    const { loading, collection, load, filters } = useCollectionLoader<Invoice, InvoiceCollection>(ModelType.INVOICES, [], () => {
        return {
            filter: {
                documentType: 'invoice',
                depositInvoiceOf: invoice.value?.getId(),
            },
        };
    });
    const success = ref(false);
    const form = useForm({});
    const { t } = useTranslation();
    const forcingTranslation = computed(() => t('actions.open'));
    const toggleDepositInvoice = (id) => {
        if (checkedDepositInvoices.value.includes(id)) {
            checkedDepositInvoices.value = checkedDepositInvoices.value.filter((item) => item !== id);
        } else {
            checkedDepositInvoices.value.push(id);
        }
    };

    useModal(
        'invoicing:invoice:final-invoice',
        async (payload: PayloadInterface) => {
            invoice.value = payload.invoice;
            await load();
            checkedDepositInvoices.value = collection.value?.filter((i) => i.status !== 'draft').map((i) => i.getId()) ?? [];
        },
        () => {
            success.value = false;
            invoice.value = finalInvoice.value = null;
            form.reset();
        }
    );

    const submit = async () => {
        const response = await form.loadUntil(endpoint.storeFinalInvoice(invoice.value!.getId(), checkedDepositInvoices.value));

        if (response.data) {
            finalInvoice.value = response.data;
            success.value = true;
        }
    };

    const openDepositInvoice = () => {
        finalInvoice.value!.edit();
        useEvent('invoicing:invoice:final-invoice:close');
    };
</script>

<template>
    <Modal
        ref="modal"
        name="invoicing:invoice:final-invoice"
    >
        <ModalLoader v-if="loading" />
        <template v-else>
            <template v-if="success">
                <ModalLottie
                    :title="$t('invoicing.invoice_final_invoice_modal.success.title')"
                    :subtitle="$t('invoicing.invoice_final_invoice_modal.success.text')"
                >
                    <div :class="$theme('modal.footer.button_container')">
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :class-name="$theme('button.style.cancel')"
                            prevent-default
                            @clicked="useEvent('invoicing:invoice:final-invoice:close')"
                            >{{ $t('actions.cancel') }}
                        </LoadingButton>
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            prevent-default
                            @clicked="openDepositInvoice"
                        >
                            {{ t('actions.open_invoice') }}
                        </LoadingButton>
                    </div>
                </ModalLottie>
            </template>
            <template v-else>
                <div :class="$theme('modal.title.container')">
                    <h2 :class="$theme('modal.title.text')">{{ $t('actions.create_final_invoice') }}</h2>
                </div>
                <form @submit.prevent="submit">
                    <div :class="$theme('modal.padding')">
                        <div v-if="collection?.isNotEmpty()">
                            <p class="mb-2 text-sm text-gray-700">{{ $t('invoicing.invoice_final_invoice_modal.existing_final_invoices.title') }}</p>
                            <p class="mb-4 text-xs text-gray-500">{{ $t('invoicing.invoice_final_invoice_modal.existing_final_invoices.text') }}</p>
                            <InvoicingInvoiceDepositInvoiceTable
                                :key="collection.getInternalId()"
                                :collection="collection"
                                checkable
                                :checked="checkedDepositInvoices"
                                @toggle="toggleDepositInvoice"
                            />
                        </div>
                        <div :class="$theme('modal.footer.button_container')">
                            <LoadingButton
                                :extra-class-name="$theme('modal.footer.button')"
                                :class-name="$theme('button.style.cancel')"
                                prevent-default
                                @clicked="useEvent('invoicing:invoice:final-invoice:close')"
                                >{{ $t('actions.cancel') }}
                            </LoadingButton>
                            <LoadingButton
                                :extra-class-name="$theme('modal.footer.button')"
                                :loading="form.isLoading"
                                type="submit"
                                >{{ $t('actions.create_final_invoice') }}</LoadingButton
                            >
                        </div>
                    </div>
                </form>
            </template>
        </template>
    </Modal>
</template>
