<script setup lang="ts">
    import { emit } from 'process';
    import { isEmail } from '~/utils/string-helpers';

    const emit = defineEmits(["changed"]);

    const props = defineProps({
  value: null,
  placeholder: null,
  multiple: { type: Boolean, default: false }
});

    const input: Ref<HTMLElement | null> = ref(null);
    const email = ref(props.value);
    const editing = ref(false);

    const displayInput = computed(() => {
        return editing.value || !props.value;
    });

    const emailBadges = computed(() => {
        return email.value.split(',').map((email) => email.trim());
    });

    const edit = () => {
        editing.value = true;
        nextTick(() => {
            input.value!.focus();
        });
    };

    const confirmEmail = () => {
        if (!validEmail()) {
            return;
        }

        editing.value = false;
        emit('changed', email.value);
    };

    const validEmail = () => {
        if (!props.multiple) {
            return isEmail(email.value);
        }

        const emails = email.value.split(',').map((email) => email.trim());
        return emails.filter((email) => !isEmail(email)).length == 0;
    };
</script>

<template>
    <div>
        <input
            class="w-full p-2 text-sm text-red-600 outline-none focus:text-black"
            type="text"
            v-model="email"
            :placeholder="placeholder"
            v-if="displayInput"
            @blur="confirmEmail"
            ref="input"
        />
        <div
            v-if="!displayInput"
            class="flex items-center space-x-2 group"
            @click.prevent="edit()"
        >
            <span
                class="inline-block px-2 py-1 text-sm text-blue-900 border rounded cursor-pointer bg-blue-50 group-hover:bg-blue-100 border-primary"
                v-for="badge in emailBadges"
                :key="badge"
            >
                {{ badge }}
            </span>
        </div>
    </div>
</template>
