import ProductPreview, { RawPreviewClient } from '../classes/ClientPreview';
export default class PreviewClientFactory {
    create(client: RawPreviewClient): ProductPreview {
        return new ProductPreview({
            name: client[0],
            email: client[1],
            phone: client[2],
            addresse: client[3],
            addresseSecondLine: client[4],
            postcode: client[5],
            city: client[6],
            country: client[7],
            vat: client[8],
        });
    }
}
